/*-- -------------------------- -->
<---       Side By Side         -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .sbs-867 {
        padding: var(--sectionPadding);
    }
    .sbs-867 .cs-container {
        width: 100%;
        /* changes to 1280px at desktop */
        max-width: 34.375rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 7vw, 4rem);
    }
    .sbs-867 .cs-content {
        /* set text aling to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 33.875rem;
        /* prevents padding from affecting width and height */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
    }

    .sbs-867 .cs-title {
        /* 17 characters wide including spaces */
        max-width: 17ch;
    }
    .sbs-867 .cs-text {
        margin-bottom: 1rem;
    }
    .sbs-867 .cs-text:last-of-type {
        margin-bottom: 2rem;
    }
    
    .sbs-867 .cs-picture {
        display: block;
        position: relative;
        width: 100%;
        max-width: 39.375rem;
        /* 400px - 775px */
        height: clamp(25rem, 70vw, 48.4375rem);
        /* clips img tag corners */
        overflow: hidden;
    }
    .sbs-867 .cs-picture img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 64rem) {
    .sbs-867 {
        padding: var(--sectionPadding);
    }
    .sbs-867 .cs-container {
        flex-direction: row;
        max-width: 80rem;
        justify-content: space-between;
    }
    .sbs-867 .cs-content {
        width: 53%;
        /* reset the padding, add the section padding back to the section container */
        padding: 0;
    }
    .sbs-867 .cs-picture {
        width: 47vw;
        position: relative;
    }
}

                                