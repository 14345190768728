/*-- -------------------------- -->
<---        Content Page        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .content-page-852 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the wave background from causing overflow issues when it goes off screen */
        overflow: hidden;
        position: relative;
        z-index: 1;
    }

    .content-page-852 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
    }

    .content-page-852 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 46.125rem;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
    }


    .content-page-852 .cs-no-margin {
        margin: 0;
    }

    .content-page-852 .cs-text {
        width: 100%;
        margin: 0 0 1rem 0;
        
    }

    .content-page-852 p:last-of-type {
        margin-bottom: 2rem;
    }

    .content-page-852 p a {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
        
    }


    .content-page-852 .cs-ul {
        width: 100%;
        margin: 0 0 1.5rem 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
    }

    .content-page-852 .cs-li {
        font-size: var(--bodyFontSize);
        list-style: none;
        line-height: 1.5em;
        width: 100%;
        color: var(--bodyTextColor);
        display: flex;
        justify-content: flex-start;
        /* push icon top the top so if the list item goes to two lines the icon stays at the top */
        align-items: flex-start;
        gap: 0.5rem;
    }

    .content-page-852 .cs-icon {
        width: 1.5rem;
        height: auto;
        /* adds extra space between the icon and top of parent so it's more centered */
        margin-top: 1px;
        display: block;
    }

    .content-page-852 img {
        width: 100%;
        height: auto;
        display: block;
    }

    .content-page-852 .cs-image-group {
        width: 50%;
        max-width: 27.0625rem;
        display: none;
        flex-direction: column;
        gap: 1.25rem;
        position: relative;
    }

    .content-page-852 .cs-image-group:after {
        /* colored box */
        content: "";
        width: 100%;
        /* 177px - 258px */
        height: clamp(11.0625rem, 19vw, 16.125rem);
        background: var(--secondaryLight);
        border-radius: 6.25rem 0 6.25rem;
        opacity: 1;
        display: block;
    }

    .content-page-852 .cs-picture {
        width: 100%;
        /* 300px - 520px */
        height: clamp(18.75rem, 40vw, 32.5rem);
        box-shadow: 0px 3.3478px 50.2169px rgba(0, 0, 0, 0.16);
        /* 125px - 200px */
        border-radius: 0 clamp(7.8125rem, 15vw, 12.5rem) 0 clamp(7.8125rem, 15vw, 12.5rem);
        /* prevents border from affecting height and width */
        box-sizing: border-box;
        /* clips img tag corners */
        overflow: hidden;
        display: block;
        position: relative;
    }

    .content-page-852 .cs-picture img {
        width: 100%;
        height: 100%;
        /* makes it act like a background image */
        object-fit: cover;
    }
}

/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    .content-page-852 .cs-container {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    .content-page-852 .cs-content {
        flex: none;
        width: 60%;
        /* sens it to the right in the 2nd position */
        order: 2;
    }

    .content-page-852 .cs-image-group {
        display: flex;
    }
}