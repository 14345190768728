/*-- -------------------------- -->
<---          Timeline          -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .timeline-971 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the white line through the bullets */
        overflow: hidden;
    }
    .timeline-971 .cs-container {
        width: 100%;
        /* changes to 1440px at tablet */
        max-width: 34.375em;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    .timeline-971 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }

    .timeline-971 .cs-title {
        max-width: 18ch;
    }
    .timeline-971 .cs-card-group {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        /* 40px - 80px */
        gap: clamp(2.5rem, 6vw, 5rem);
        position: relative;
    }
    .timeline-971 .cs-line {
        /* white line through the bullets */
        content: "";
        width: 1px;
        background: #dddddd;
        opacity: 1;
        position: absolute;
        display: block;
        top: 0;
        /* send it way down to reach the bottom of the section, and have some overlap so if you add content and the ul gets longer, it will still be at the bottom */
        bottom: -18.75rem;
        /* half the width of the bullet */
        left: 0.375rem;
    }

    
    .timeline-971 .cs-item {
        width: 100%;
        max-width: 25rem;
        list-style: none;
        padding: 0 0 0 3.25rem;
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        position: relative;
    }
   
    .timeline-971 .cs-h3 {
        /* 20px - 25px */
        font-size: var(--ctaHeader);
        line-height: 1.2em;
        font-weight: 700;
        /* changes to 100% at tablet */
        width: 80%;
        margin: 0 0 1rem 0;
        color: var(--headerColor);
        position: relative;
       min-height: 60px;   
     }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    .timeline-971 .cs-container {
        max-width: 90rem;
    }
    .timeline-971 .cs-card-group {
        flex-direction: row;
    }
    .timeline-971 .cs-card-group:before {
        width: 100%;
        height: 1px;
        top: auto;
        bottom: 0.375rem;
        /* these next two properties center the line in the UL */
        left: 50%;
        transform: translateX(-50%);
    }
    .timeline-971 .cs-item {
        padding: 0 0 4.125rem 0;
        z-index: 1;
    }
    .timeline-971 .cs-item::before {
        top: auto;
        bottom: 0rem;
    }
    .timeline-971 .cs-h3 {
        width: 100%;
    }
}

                                