/*-- -------------------------- -->
<---        Content Page        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    @keyframes floatAnimation {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-2rem);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes floatAnimation2 {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-3rem);
        }
        100% {
            transform: translateY(0);
        }
    }
    .content-page-1399 {
        padding: var(--sectionPadding);
        background-color: #fff;
        /* clips the wave background from causing overflow issues when it goes off screen */
        overflow: hidden;
        position: relative;
        z-index: 1;
    }
    .content-page-1399 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* 40px - 64px */
        gap: clamp(2.5rem, 4vw, 4rem);
        position: relative;
    }
    .content-page-1399 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        max-width: 46.125rem;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
    }
    .content-page-1399 .cs-title {
        width: 100%;
        max-width: 100%;
        
    }
    .content-page-1399 .cs-ul {
        width: 100%;
        margin: 0 0 2rem 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        column-gap: 2.5rem;
        row-gap: 0.75rem;
      }
      .content-page-1399 .cs-li {
        font-size: var(--bodyFontSize);
        list-style: none;
        line-height: 1.5em;
        width: 100%;
        color: var(--bodyTextColor);
        display: flex;
        justify-content: flex-start;
        /* push icon top the top so if the list item goes to two lines the icon stays at the top */
        align-items: flex-start;
        gap: 0.5rem;
      }
      .content-page-1399 .cs-icon {
        width: 1.5rem;
        height: auto;
        /* adds extra space between the icon and top of parent so it's more centered */
        margin-top: 1px;
        display: block;
      }
    .content-page-1399 .cs-button-solid {
        margin-bottom: 2rem;
    }
    .content-page-1399 .cs-no-margin {
        margin: 0;
    }
    .content-page-1399 .cs-color {
        color: var(--primary);
    }
    .content-page-1399 p { 
        margin: 0 0 1rem 0;
    }
    .content-page-1399 p:last-of-type {
        margin-bottom: 2rem;
    }
    .content-page-1399 p a {
        font-size: inherit;
        line-height: inherit;
        text-decoration: underline;
        filter: brightness(120%);
    }
    
    .content-page-1399 img {
        width: 100%;
        height: auto;
        display: block;
    }
    .content-page-1399 .cs-image-group {
        width: 100%;
        display: none;
        flex-direction: column;
        /* 16px - 20px */
        gap: clamp(1rem, 2.6vw, 1.25rem);
        position: relative;
    }
    .content-page-1399 .cs-flex {
        position: relative;
    }
    .content-page-1399 .cs-background {
        width: 100%;
        height: 100%;
        /* 32px - 48px */
        border-radius: clamp(2rem, 4vw, 3rem);
        /* clips the corners of the children around the border radius */
        overflow: hidden;
        /* makes it cover the parent dimensions */
        object-fit: cover;
        display: block;
    }
    .content-page-1399 .cs-background img {
        width: 100%;
        height: 100%;
        /* makes it cover the parent like a backgorund image */
        object-fit: cover;
        display: block;
    }
    .content-page-1399 .cs-box {
        text-align: left;
        width: 100%;
        max-width: 12.5rem;
        padding: 2rem;
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        background-color: var(--primary);
        /* 48px - 80px */
        border-radius: 2rem;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        gap: 1.5rem;
        position: absolute;
        /* 12px - 20px */
        right: clamp(0.75rem, 1.9vw, 1.5rem);
        /* 12px - 20px */
        bottom: clamp(0.75rem, 1.9vw, 1.5rem);
    }
    .content-page-1399 .cs-box-icon {
        width: 3.75rem;
        height: auto;
        display: block;
    }
    .content-page-1399 .cs-desc {
        font-size: 1.25rem;
        line-height: 1.2em;
        text-align: inherit;
        font-weight: 700;
        width: 100%;
        margin: 0;
        color: var(--bodyTextColorWhite);
    }
    .content-page-1399 .cs-card-group {
        width: 100%;
        max-width: 39.375rem;
        margin: 0 0 1.5rem 0;
        padding: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(15.265rem, 1fr));
        align-items: center;
        gap: clamp(1rem, 2vw, 1.25rem);
    }
    .content-page-1399 .cs-item {
        list-style: none;
        /* 16px - 24px */
        padding: clamp(1rem, 3vw, 1.5rem);
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        background-color: #f7f7f7;
        border-radius: 1rem;
        border: 1px solid #e8e8e8;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
    }
    .content-page-1399 .cs-item:before {
        display: none;
    }
   
    .content-page-1399 .cs-item-text {
        font-size: 0.875rem;
        line-height: 1.5em;
        margin: 0;
        color: var(--bodyTextColor);
    }
    .content-page-1399 .cs-item-text:last-of-type {
        margin: 0;
    }
    .content-page-1399 .cs-ul {
        width: 100%;
        margin: 0 0 1.5rem 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.75rem;
    }
    .content-page-1399 .cs-li {
        font-size: var(--bodyFontSize);
        list-style: none;
        line-height: 1.5em;
        width: 100%;
        color: var(--bodyTextColor);
        display: flex;
        justify-content: flex-start;
        /* push icon top the top so if the list item goes to two lines the icon stays at the top */
        align-items: flex-start;
        gap: 0.5rem;
    }
    .content-page-1399 .cs-icon {
        width: 1.5rem;
        height: auto;
        /* adds extra space between the icon and top of parent so it's more centered */
        margin-top: 1px;
        display: block;
    }
    .content-page-1399 .cs-bubbles {
        font-size: min(2.5vw, 0.7em);
        width: 36.375em;
        height: 34.8125em;
        display: none;
        position: absolute;
        /* changes to auto at larger desktop */
        right: -16.25em;
        /* changes to -220px at large desktop */
        bottom: -0.125em;
        z-index: -1;
    }
    
    .content-page-1399 .cs-bubbles:before {
        /* white border bubble */
        content: "";
        width: 29em;
        height: 29em;
        background: transparent;
        border: 1px solid #1a1a1a;
        border-radius: 50%;
        opacity: 1;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        animation-name: floatAnimation;
        animation-duration: 5s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    .content-page-1399 .cs-bubbles:after {
        /* orange bubble */
        content: "";
        width: 22.875em;
        height: 22.875em;
        background: var(--secondaryLight);
        opacity: 0.15;
        border-radius: 50%;
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        animation-name: floatAnimation2;
        animation-duration: 14s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    
    .content-page-1399 .cs-content {
        width: 50%;
        max-width: 42.125rem;
        /* prevents flexbox from squishing it */
        flex: none;
    }
    .content-page-1399 .cs-image-group {
        width: 44%;
        max-width: 33.875rem;
        display: flex;
        /* sends it to the right in the 2nd position */
        order: 2;
    }
}
/* Desktop - 1200px */
@media only screen and (min-width: 75rem) {
    .content-page-1399 .cs-content {
        width: 53%;
    }
    .content-page-1399 .cs-image-group {
        width: 43%;
    }
    .content-page-1399 .cs-box {
        display: flex;
    }
    .content-page-1399 .cs-bubbles {
        font-size: 1em;
        margin-left: 37.5rem;
        display: block;
        right: auto;
        left: 50%;
        bottom: -0.75rem;
    }
}

@media only screen and (min-width: 0rem) {
    .button-box-1483 {
      width: 100%;
      max-width: 39.375rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 1.5rem;
    }
    .button-box-1483 .cs-link {
      text-decoration: none;
      padding-right: 2.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      position: relative;
    }
    .button-box-1483 .cs-link:hover .cs-wrapper {
      transform: scale(1.2);
    }
    .button-box-1483 .cs-wrapper {
      width: 3.25rem;
      height: 3.25rem;
      border: 1px solid var(--secondary);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      /* prevents flexbox from squishing it */
      flex: none;
      transition: transform .3s;
    }
    .button-box-1483 .cs-img {
      width: 3.25rem;
      height: auto;
      border: 2px solid #BABABA;
      border-radius: 50%;
      /* prevents border from affecting height and width */
      box-sizing: border-box;
    }
    .button-box-1483 .cs-icon {
      width: 1.5rem;
      height: auto;
      display: block;
    }
    .button-box-1483 .cs-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.25rem;
    }
    .button-box-1483 .cs-header {
      font-size: 1.5625rem;
      line-height: 1.2em;
      font-weight: 700;
      margin: 0;
      color: var(--headerColor);
      display: block;
    }
    .button-box-1483 .cs-phone {
      font-size: 1.25rem;
      line-height: 1.2em;
      font-weight: 500;
      margin: 0;
      color: var(--headerColor);
      display: block;
    }
    .button-box-1483 .cs-job {
      font-size: 1rem;
      line-height: 1.2em;
      font-weight: 700;
      margin: 0;
      color: var(--secondary);
      display: block;
    }
    .button-box-1483 .cs-link-content {
      font-size: 1rem;
      line-height: 1.2em;
      font-weight: 400;
      margin: 0;
      color: var(--bodyTextColor);
      display: block;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    .button-box-1483 .cs-link:nth-of-type(1) {
      border-right: 1px solid #E8E8E8;
    }
  }                                