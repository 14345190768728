/*-- -------------------------- -->
<---           Hero             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .hero-1469 {
      /* 200px - 400px - leaving extra space for the navigation */
      padding: clamp(12.5rem, 29.95vw, 25rem) 1rem 0;
      /* 100px - 34px */
      padding-bottom: clamp(6.25rem, 20vw, 21.25rem);
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
    .hero-1469:before {
      content: '';
      width: 100%;
      height: 100%;
      background: var(--backgroundColor);
      opacity: .9;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
    }
    .hero-1469 .cs-container {
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
      position: relative;
    }
    .hero-1469 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: center;
      width: 100%;
      max-width: 50rem;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: center;
    }
    .hero-1469 .cs-topper {
      /* 13px - 16px */
      font-size: var(--topperTextSize);
      line-height: 1.2em;
      text-transform: uppercase;
      text-align: inherit;
      letter-spacing: .1em;
      font-weight: 700;
      color: var(--secondary);
      margin-bottom: 0.5rem;
      display: block;
    }
    .hero-1469 .cs-title {
      /* 39px - 61px */
      font-size:  var(--hTextSize);
      font-weight: 700;
      line-height: 1.2em;
      text-align: inherit;
      max-width: 23ch;
      margin: 0 0 clamp(2rem, 4vw, 2.5rem) 0;
      color: var(--headerColor);
      position: relative;
    }
    .hero-1469 .cs-background {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -2;
    }
    
    .hero-1469 .cs-background img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      /* makes the top of the image start at the top of the parent */
      object-position: top;
    }
    .hero-1469 .cs-hide {
      display: none;
    }
    .hero-1469 .cs-curve {
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      bottom: -5px;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    .hero-1469:before {
      background: linear-gradient(89deg, var(--backgroundColor) 44.46%, rgba(255, 243, 236, 0) 78.47%);
      opacity: 1;
    }
    .hero-1469 .cs-container {
      flex-direction: row;
      justify-content: space-between;
    }
    .hero-1469 .cs-content {
      width: 67%;
      text-align: left;
      align-items: flex-start;
    }
    .hero-1469 .cs-hide {
      display: block;
      position: absolute;
      z-index: 10;
    }
    
    
  }

  /* Large Desktop Parallax Effect - 1600px */
  @media only screen and (min-width: 100rem) {
    .hero-1469 .cs-background {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
    }   
  }

                                  