/*-- -------------------------- -->
  <---          Services          -->
  <--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .services-1495 {
        padding: var(--sectionPadding);
        position: relative;
        z-index: 1;
        background-color: #fff;
        overflow: hidden;
    }

    .services-1495 .cs-container {
        width: 100%;
        /* changes to 1280px at desktop */
        max-width: 56.25rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
        position: relative;
        z-index: 15;
    }

    .services-1495 .cs-content {
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
        width: 100%;
        /* set text align to left if content needs to be left aligned */
        text-align: center;
    }

    .services-1495 .cs-title {
        max-width: 23ch;
    }

    .services-1495 .cs-card-group {
        display: grid;
        align-items: stretch;
        grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
        margin: 0;
        width: 100%;
        padding: 0;
        /* 16px - 20px */
        gap: clamp(1rem, 2vw, 1.25rem);
    }


    .services-1495 .cs-hills {
        /* hills Color */
        --hillBG: var(--secondaryLight);
        width: 140%;
        min-width: 120rem;
        height: auto;
        position: absolute;
        /* positions off the top at desktop */
        bottom: -6.25rem;
        left: 50%;
        transform: translateX(-50%);
    }

}

/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    .services-1495 .cs-container {
        max-width: 80rem;
    }

    .services-1495 .cs-card-group {
        grid-template-columns: repeat(12, 1fr);
    }

    .services-1495 .cs-hills {
        top: 75%;
        bottom: auto;
    }
}