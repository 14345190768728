/*-- -------------------------- -->
<---            CTA             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .cta-1506 {
      padding: var(--sectionPadding);
      padding-bottom: clamp(1.875rem, 5vw, 3.125rem);;
      overflow: hidden;
    }
    .cta-1506 .cs-container {
      position: relative;
      width: 100%;
      /* changes to 1280px at tablet */
      max-width: 34.375rem;
      margin: auto;
      /* 16px - 20px */
      padding: clamp(1rem, 2vw, 1.25rem) clamp(1rem, 2vw, 1.25rem);
      overflow: hidden;
      /* 32px - 100px */
      border-radius: clamp(2rem, 5vw, 6.25rem);
      /* prevents padding from affecting height abd width */
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 28px - 36px */
      gap: clamp(1.75rem, 4vw, 2.25rem);
      position: relative;
      z-index: 1;
    }
    .cta-1506 .cs-container:before {
      /* background-color */
      content: '';
      width: 100%;
      height: 100%;
      background: var(--backgroundColor);
      opacity: 1;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
    }
    .cta-1506 .cs-content {
      /* set text align to left if content needs to be left aligned */
      z-index: 1;
      text-align: center;
      width: 100%;
      max-width: 32.625rem;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: center;
      position: relative;
    }
    .cta-1506 .cs-title,
    .cta-1506 .cs-text {
      max-width: 31.25rem;
    }
    .cta-1506 .cs-text {
      margin-bottom: 2rem;
    
    }

    .cta-1506 .cs-blob {
      --blobColor: var(--secondaryLight);
      display: none;
      position: absolute;
    }
    .cta-1506 .cs-blob1 {
      width: 16.9375rem;
      height: auto;
      top: 0;
      left: 0;
      opacity: 1;
    }
    .cta-1506 .cs-blob2 {
      width: 28.8125rem;
      height: auto;
      bottom: 0;
      right: 0;
      opacity: .25;
    }
    .cta-1506 .cs-form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      position: relative;
      z-index: 1;
    }
    .cta-1506 .cs-input {
      font-size: 1rem;
      width: 100%;
      /* 46px - 72px */
      height: clamp(2.875rem, 8vw, 4.5rem);
      margin: 0;
      padding: 0;
      padding-left: 1.25rem;
      border: none;
      border-radius: 5rem;
      /* prevents padding from adding to width and height */
      box-sizing: border-box;
      display: block;
    }
    .cta-1506 .cs-input::placeholder {
      color: #767676;
    }
    .cta-1506 .cs-button-solid {
      font-size: 1rem;
      /* 46px - 56px */
      line-height: clamp(2.875rem, 5.5vw, 3.5rem);
      text-decoration: none;
      font-weight: 700;
      text-align: center;
      margin: 0;
      color: #fff;
      overflow: hidden;
      min-width: 9.375rem;
      padding: 0 2rem;
      background-color: var(--primary);
      border-radius: 5rem;
      display: inline-block;
      
      z-index: 1;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
    }
    .cta-1506 .cs-button-solid:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 0%;
      background: #000;
      opacity: 1;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width .3s;
    }
    .cta-1506 .cs-button-solid:hover {
      cursor: pointer;
    }
    .cta-1506 .cs-button-solid:hover:before {
      width: 100%;
    }
    .cta-1506 .cs-submit {
      width: 100%;
      border: none;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    .cta-1506 .cs-container {
      max-width: 80rem;
    }
    .cta-1506 .cs-blob {
      display: block;
    }
    .cta-1506 .cs-input {
      width: 100%;
    }
    .cta-1506 .cs-submit {
      width: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
    }
  }
  /* Desktop - 1024px */
  @media only screen and (min-width: 64rem) {
    .cta-1506 .cs-container {
      padding: 5rem 1rem;
    }
    
  }