.cs-button-solid {
  font-size: var(--buttonTextSize);
  /* 46px - 56px */
  line-height: clamp(2.875rem, 5.5vw, 3.5rem);
  text-decoration: none;
  font-weight: 700;
  overflow: hidden;
  margin: 0;
  padding: 0 3rem;
  border: none;
  border-radius: 1.875rem;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition: color .3s;
}

.cs-button-solid:before {
  content: '';
  position: absolute;
  display: block;
  height: 100%;
  width: 0%;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width .3s;
}

.cs-button-solid:hover {
  color: var(--primary);
}

.cs-button-solid:hover:before {
  width: 100%;
}

.cta-button {
  background-color: var(--primary);
  color: var(--bodyTextColorWhite);
}

.cta-button:before {
  background: black;
}

.cta-button:hover {
  color: var(--hoverButtonColor);
}

.link-button {
  background-color: var(--secondaryLight);
  color: #fff;
}

.link-button:before {
  background: #fff;
}

.link-button:hover {
  color: var(--secondaryLight);
}

.off-button {
  opacity: 0;
}