/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .cs-item {
      text-align: center;
      list-style: none;
      /* 16px - 24px */
      padding: 1.5rem clamp(1rem, 2.4vw, 1.5rem);
      /* prevents padding from affecting height and width */
      box-sizing: border-box;
      background-color: var(--backgroundColor);
      border: 1px solid #E8E8E8;
      /* 40px - 60px */
      border-radius: clamp(2.5rem, 5vw, 3.75rem);
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      transition: border-color 0.3s;

    }

    .cs-item:hover {
      border-color: var(--secondary);
    }
    .cs-item:hover .cs-picture img {
      transform: scale(1.2);
    }
    .cs-item:hover .cs-link {
        color: var(--primary);
    }
   
    .cs-item:hover .cs-arrow {
      filter: initial;
    }
    .cs-image-group {
      /* done in ems so we can use font size to scale it on desktop */
      width: 16.0625em;
      height: 12.5em;
      /* 16px - 24px */
      margin: 0 0 clamp(1rem, 2.4vw, 1.5rem);
      position: relative;
      z-index: 1;
      margin: auto;
    }
    .cs-picture {
      position: relative;
      display: block;
      height: 97%;
      width: 97%;
      /* clips the image from overflowing parent on hover */
      overflow: hidden;
    }
    .cs-picture img {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.65s;
    }
    .cs-mask {
      /* SVG Colors */
      --maskBorder: #e8e8e8;
      --maskBG: var(--backgroundColor);
      position: absolute;
      top: -1px;
      left: -1px;
      bottom: -1px;
      right: -1px;
    }
    .cs-info {
      display: flex;
      flex-direction: column;
    }
    .cs-h3 {
      /* 20px - 25px */
      font-size: var(--ctaHeader);
      text-align: inherit;
      line-height: 1.5em;
      font-weight: 700;
      width: 100%;
      margin: 0 0 0.5rem 0;
      /* prevents padding and border from affecting height and width */
      box-sizing: border-box;
      color: var(--headerColor);
      transition: background-color 0.3s;
      transition: color 0.3s;
    }
    
  }

  /* Desktop - 1024px */
  @media only screen and (min-width: 1024px) {
    .cs-item {
      grid-column: span 4;
    }
    .services-1495 .cs-image-group {
      /* scaling it down so it all fits proportionally */
      font-size: min(1.2vw, 1em);
    }
  }

  /* Desktop - 1024px */
  @media only screen and (min-width: 1350px) {
    .cs-item {
      grid-column: span 3;
    }
  }