/*-- -------------------------- -->
<---          Contact           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    .contact-323 {
        padding: var(--sectionPadding);
        position: relative;
        z-index: 1;
    }
    .contact-323 .cs-container {
        width: 100%;
        /* changes to 1280px at desktop */
        max-width: 36.25rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3.25rem;
    }
    .contact-323 .cs-content {
        text-align: left;
    }

    .contact-323 .cs-text {
        /* 24px - 32px */
        margin-bottom: clamp(1.5rem, 5vw, 2rem);
    }
    .contact-323 .cs-form {
        /* 20px - 48px top and bottom */
        /* 20px - 32px left and right */
        padding: clamp(1.25rem, 4.18vw, 3rem) clamp(1.25rem, 4.18vw, 2rem);
        background-color: var(--backgroundColor);
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.75rem;
    }
    .contact-323 .cs-label {
        /* 14px - 16px */
        font-size: var(--inputLabel);
        width: 100%;
        color: var(--headerColor);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 0.25rem;
    }
    .contact-323 .cs-input {
        font-size: 1rem;
        width: 100%;
        height: 3.5rem;
        padding: 0;
        padding-left: 1.5rem;
        border-radius: 0.5rem;
        color: var(--headerColor);
        background-color: #fff;
        border: none;
        /* prevents padding from adding to height and width */
        box-sizing: border-box;
    }
    .contact-323 .cs-input::placeholder {
        color: #7d799c;
        opacity: 0.6;
    }
    .contact-323 .cs-textarea {
        min-height: 7.5rem;
        padding-top: 1.5rem;
        margin-bottom: 0.75rem;
        font-family: inherit;
        resize: none;
    }
    
    .contact-323 .cs-map {
        width: 100%;
        max-width: 39.375rem;
        /* 328px - 425px */
        height: clamp(20.5rem, 63.4vw, 26.5625rem);
        border-radius: 1.5rem;
        /* clips the corners of the img tag */
        overflow: hidden;
        position: relative;
        display: block;
    }
    .contact-323 .cs-map img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    .contact-323 .cs-container {
        max-width: 80rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 3.25rem;
    }
    .contact-323 .cs-content {
        max-width: 35.4375rem;
    }
    .contact-323 .cs-title,
    .contact-323 .cs-topper,
    .contact-323 .cs-text {
        text-align: left;
        margin-left: 0;
    }
    .contact-323 .cs-title {
        max-width: 32.625rem;
    }
    .contact-323 .cs-email,
    .contact-323 .cs-phone {
        width: 47%;
    }
    .contact-323 .cs-map {
        height: auto;
        aspect-ratio: 0.89871612;
    }
}

                                