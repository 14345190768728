/*-- -------------------------- -->
<---          Footer            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
	.cs-footer-842 {
		padding: var(--sectionPadding);
		/* 30px - 50px */
		padding-bottom: clamp(1.875rem, 5vw, 3.125rem);
		background-color: var(--backgroundColor);
		position: relative;
	}
	.cs-footer-842 .cs-wave {
		width: 100%;
		height: auto;
		position: absolute;
		left: 0;
		bottom: 99%;
		fill: var(--backgroundColor);
	}
	.cs-footer-842 .cs-container {
		width: 100%;
		/* reset on tablet */
		max-width: 34.375rem;
		margin: auto;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		column-gap: 3rem;
		row-gap: 2rem;
	}
	.cs-footer-842 .cs-logo-group {
		/* takes up all the space, lets the other ul's wrap below it */
		width: 100%;
		position: relative;
	}
	.cs-footer-842 .cs-logo {
		width: 13.125rem;
		height: auto;
		margin: 0 0 1.5rem 0;
		display: block;
	}
	.cs-footer-842 .cs-logo-img {
		width: 100%;
		height: auto;
	}
	.cs-footer-842 .cs-text {
		font-size: 1rem;
		line-height: 1.5em;
		margin: 0 0 1.5rem 0;
		max-width: 25rem;
		color: var(--bodyTextColor);
	}
	.cs-footer-842 .cs-social {
		display: inline-flex;
		justify-content: flex-start;
		gap: 0.75em;
	}
	.cs-footer-842 .cs-social-link {
		width: 2rem;
		height: 2rem;
		background-color: var(--bodyTextColor);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 1;
		transition: transform 0.3s, background-color 0.3s;
	}
	.cs-footer-842 .cs-social-link:hover {
		background-color: var(--secondary);
		transform: translateY(-0.1875rem);
	}
	.cs-footer-842 .cs-social-link:hover .cs-social-img {
		filter: grayscale(1) brightness(1000%);
	}
	.cs-footer-842 .cs-social-img {
		height: 0.75rem;
		width: auto;
		display: block;
	}

	.cs-footer-842 .cs-ndis.cs-social-link:hover .cs-social-img {
		background-color: none;
		transform: none;
		filter: none;
	}

	.cs-footer-842 .cs-ndis .cs-social-img {
		height: auto;
	}

	.cs-footer-842 .cs-header {
		font-size: var(--contactHeader);
		line-height: 1.5em;
		font-weight: 700;
		margin: 0 0 1.25rem 0;
		color: var(--bodyTextColor);
		display: block;
		position: relative;
	}
	.cs-footer-842 .cs-ul {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		gap: 0.75rem;
	}
	.cs-footer-842 .cs-ul-1 {
		width: 100%;
		max-width: 21.875rem;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		column-gap: 2rem;
	}
	.cs-footer-842 .cs-ul-1 .cs-li {
		width: 44%;
	}
	.cs-footer-842 .cs-li {
		list-style: none;
	}
	.cs-footer-842 .cs-link {
		font-size: 1rem;
		text-decoration: none;
		line-height: 1.5em;
		text-align: left;
		color: var(--bodyTextColor);
		display: inline-flex;
		align-items: flex-start;
		gap: 0.5rem;
		transition: color 0.3s;
	}

	.cs-footer-842 .cs-link {
		font-size: 1rem;
		text-decoration: none;
		line-height: 1.5em;
		text-align: left;
		color: var(--bodyTextColor);
		display: inline-flex;
		align-items: flex-start;
		gap: 0.5rem;
		transition: color 0.3s;
	}

	.cs-footer-842 .cs-active {
		color: var(--secondary);
	}

	.cs-footer-842 .cs-link:hover {
		color: var(--secondary);
	}
	.cs-footer-842 .cs-icon {
		width: 1.25rem;
		height: auto;
		margin-right: 0.125rem;
		display: block;
	}
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
	.cs-footer-842 .cs-container {
		max-width: 80rem;
		/* 48px - 64px */
		row-gap: clamp(3rem, 7vw, 4rem);
	}

	.cs-div-elders {
		max-width: 100%;
	}
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
	.cs-footer-842 .cs-container {
		/* pushes all flex children to the top */
		align-items: flex-start;
		justify-content: flex-end;
		flex-wrap: nowrap;
	}
	.cs-footer-842 .cs-logo-group {
		width: 25%;
		max-width: 25rem;
		/* pushes everything to the right of it as far as possible in a flexbox */
		margin-right: auto;
	}
	.cs-footer-842 .cs-text {
		width: 100%;
	}
	.cs-footer-842 .cs-social {
		flex-direction: row;
		position: relative;
		top: auto;
		right: auto;
	}

	.elders {
		font-size: 10px;
	}
	.cs-div-elders {
		margin-top: 10px;
		max-width: 20%;
	}
}
